<template>
  <b-dropdown offset="0"
              right
              class="dropdown-menu-left hide_caret default_style_dropdown"
              ref="biolink-dropdown-scroll"
              style="min-width: 278px !important;"
              :no-caret="true">
    <div @click="getAnalyticsFilters.brand = getWorkspace._id"
         class="dropdown_header border !border-[#757A8A] font-semibold !h-[2.375rem] px-2.5 py-2 !rounded-[8px] d-flex align-items-center "
         slot="button-content">
           <span
             v-if="getAnalyticsFilters.bio_link.shorten_url">{{
               limitTextLength(getAnalyticsFilters.bio_link.shorten_url, 20)
             }}</span>
      <span v-else>Select Link</span>
      <span class="arrow_icon ml-auto"><i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i></span>
    </div>
    <ul @scroll="lazyScrollBrand('biolink-dropdown-scroll','fetchBrandList',1)"
        style="max-height: 20rem; overflow-x: hidden; overflow-y: auto;" id="biolink-dropdown-scroll"
        class="!min-w-[278px] text-[#202324] py-1 text-[0.875rem] shadow-lg bg-white rounded-tl-lg font-poppins rounded-bl-lg rounded-br-lg">
      <div
        class="mx-[1rem] h-[2.375rem] mb-[1rem] mt-[0.8rem] border !border-[#757A8A] rounded-lg justify-between px-2 items-center flex">
        <input class="w-full h-auto !border-none" style="font-size:13px;" type="text" v-model="search_link"
               placeholder="Search by url/slug"
               @keyup="getAnalyticsLinkSearch(search_link)">
        <i
          class="fal fa-search"></i>
      </div>
      <hr>
      <template class="">
        <template v-if="loader">
          <clip-loader :size="size" :color="color"></clip-loader>
        </template>
        <template v-if="getAnalyticsFilteredLinks.length > 0 && !loader">
          <li
            class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex justify-between items-center font-poppins"
            v-for="link in getAnalyticsFilteredLinks"
          >
            <Checkbox
              :id="link._id"
              :label="'https://' + link.shorten_domain + '/' + link.shorten_url"
              @click="selectItem(link)"
              labelClass="text-[#3C4549]"
              customClass="w-full justify-between flex-row-reverse"
            ></Checkbox>
          </li>

        </template>
        <li v-else class="font-poppins text-center text-[12px] my-3">
          No Brand selected or No links found.
        </li>
      </template>
    </ul>
  </b-dropdown>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import debounce from 'lodash.debounce'
import {DARK_PURPLE_LOADER_COLOR} from '../../../../common/constants'

export default ({
  components: {
    Checkbox: () => import('@/ui/ui-kit/v2/Checkbox.vue')
  },
  data() {
    return {
      links: [],
      search_link: '',
      loader: false,
      search_requests: [],
      page: 1,
      color: DARK_PURPLE_LOADER_COLOR,
      size: '12px'
    }
  },
  computed: {
    ...mapGetters(['getAnalyticsFilters', 'getWorkspace']),
    getAnalyticsFilteredLinks() {
      return this.links
    },
  },
  methods: {
    async searchLinksDirectly(value) {
      let res = await this.$store.dispatch('searchBioLinks', {
        brand_id: this.getWorkspace._id,
        page: 1,
        value
      })
      if (res && res.data.status) {
        this.page = res.data.data.current_page
        this.links = res.data.data.data
      }
    },
    selectItem(link) {
      this.getAnalyticsFilters.bio_link = link
      this.$refs.dropdown.hide()
    },
    getAnalyticsLinkSearch: debounce(async function (key) {
      this.loader = true
      await this.searchLinksDirectly(key)
      this.loader = false
    }, 250)
  }
})
</script>
